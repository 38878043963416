<template>
  <div class="cs-list">
    <div class="top-box-out">
      <div class="top-box-in">
        <div class="ipt-box-out">
          <i class="el-icon-arrow-left" @click="$router.back()"></i>
          <div class="ipt-box">
            <input type="text" v-model="name">
            <i class="el-icon-search" @click="searchBtn"></i>
          </div>
          <img src="@/assets/img/header/fl.png">
        </div>
        <div class="cate-list">
          <div @click="selectCate('parent')">
            <div class="img-box">
              <img :src="parent.icon_path && parent.icon_path.code" v-if="parent.icon_path && parent.icon_path.code">
            </div>
            <p>{{parent.name_as || '父级类目别名'}}</p>
          </div>
          <div @click="selectCate('child')">
            <div class="img-box">
              <img :src="child.icon_path && child.icon_path.code" v-if="child.icon_path && child.icon_path.code">
            </div>
            <p>{{child.name_as || '子级类目别名'}}</p>
          </div>
          <div @click="selectCate('last')" >
            <div class="img-box">
              <img :src="last.icon_path && last.icon_path.code" v-if="last.icon_path && last.icon_path.code">
            </div>
            <p>{{last.name_as || '孙级类目别名'}}</p>
          </div>
        </div>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
      <div class="goods-list">
        <div :class="{'active': id == item.id}" v-for="(item,index) in goodsList" :key="index" @click="lookDetail(item)">
          <div class="left">
            <img :src="item.file && item.file.image_url">
            <p>￥{{item.product_sku && item.product_sku[0].sale_price}}</p>
          </div>
          <div class="right">
            <p class="title ellipsisText">{{item.name}}</p>
            <p class="title-sub ellipsisText3">{{item.subject}}</p>
            <p class="guige ellipsisText">规格：{{item.product_sku | guige}}</p>
            <p class="guige ellipsisText">SPU：{{item.serial_number}}</p>
          </div>
        </div>
      </div>
    </van-list>
    <van-popup v-model="show" position="top" round :close-on-click-overlay="false">
      <div class="pop-content">
        <!-- <div class="all-list"> -->
        <template v-if="type== 'parent'">
          <div :class="['ellipsisText',{'active-item': item.id == parent.category_id}]" v-for="(item,index) in navList" :key="index" @click="selectThis(item,'parent')">{{item.name_as}}</div>
          <p v-if="navList.length == 0" class="no-data">暂无数据</p>
        </template>

        <template v-if="type== 'child'">
          <div :class="['ellipsisText',{'active-item': item.id == child.category_id}]" v-for="(item,index) in childList" :key="index" @click="selectThis(item,'child')">{{item.name_as}}</div>
          <p v-if="childList.length == 0" class="no-data">暂无数据</p>
        </template>
        <template v-if="type== 'last'">
          <div :class="['ellipsisText',{'active-item': item.id == last.category_id}]" v-for="(item,index) in lastLits" :key="index" @click="selectThis(item,'last')">{{item.name_as}}</div>
          <p v-if="lastLits.length == 0" class="no-data">暂无数据</p>
        </template>
        <!-- </div> -->
      </div>
    </van-popup>
  </div>
</template>
<script>
import { get_sku_list, get_shop_category } from "@/assets/api/index.js";
export default {
  data() {
    return {
      type: "",
      goodsList: [],
      id: "",
      parent: {},
      child: {},
      last: {},
      loading: false,
      finished: false,
      show: false,
      navList: [],
      childList: [],
      lastLits: [],
      name: "",
      page: 1,
      category_id: "",
      category_name: "",
    };
  },
  filters: {
    guige(val) {
      if (val && val.length != 0) {
        let arr = val.map((item) => item.validity);
        let str = "";
        val.forEach((item) => {
          str += `${item.validity}天；`;
        });
        return str;
        console.log(str, "====??");
      } else {
        return "--";
      }
    },
  },
  created() {
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
    if (this.$route.query.category_id) {
      this.category_id = this.$route.query.category_id;
      this.category_name = this.$route.query.category_name;
      this.getCateList();
    }

    
  },
  mounted() {
    if (
      this.$store.state.customData &&
      this.$store.state.customData.category.length != 0
    ) {
      console.log(
        this.$store.state.customData.category,
        "this.$store.state.customData.category"
      );

      this.$store.state.customData.category.forEach((item) => {
        item.forEach((citem) => {
          if (!this.navList.some((i) => i.id === citem.id)) {
            this.navList.push(citem);
          }
        });
      });
    }
  },
  methods: {
    selectCate(type) {
      let cloneType = JSON.parse(JSON.stringify(this.type));
      console.log(cloneType, type);
      this.type = type;
      if (cloneType == type) {
        this.show = !this.show;
      } else {
        this.show = true;
      }
    },
    searchBtn() {
      this.goodsList = [];
      this.getList();
    },
    selectThis(item, objKey) {
      console.log(objKey, "[]");
      this[objKey] = item;
      this.category_id = item.id;
      this.show = false;
      this.goodsList = [];
      if (objKey == "parent") {
        this.child = {};
        this.last = {};
        this.childList = [] 
         this.lastLits = [];
      }
      if (objKey == "child") {
        this.last = {};
        this.lastLits = [];
      }
      this.getCateList();
      this.getList();
    },
    lookDetail(item) {
      this.id = item.id;
      this.$router.push({
        path: "/shopStore/detail",
        query: {
          id: item.id,
          category_id: item.category_id,
        },
      });
      console.log(item, "item");
    },
    //获取平台类目
    getCateList() {
      get_shop_category({
        company_id: 0,
        plat_category_id: this.category_id,
        category_name: this.$route.query.category_name,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.length != 0) {
            if (this.type == "" || this.type == "parent") {
              this.parent = res.data[0];
              if (res.data && res.data[0].children.length != 0) {
                this.childList = res.data[0].children;
              }
            }
            if (this.type == "child") {
              if (res.data && res.data[0].children.length != 0) {
                this.lastLits = res.data[0].children;
              }
            }
            // if(this.type == 'last'){
            //   if (res.data && res.data[0].children.length != 0) {
            //     this.lastLits = res.data[0].children;
            //   }
            // }
          }
        }
      });
    },
    getList() {
      let category_id = [];
      if (
        this.$route.query.category_id &&
        this.$route.query.category_id != ""
      ) {
        category_id = [this.$route.query.category_id];
      }

      get_sku_list({
        brand_id: [],
        is_new_added: null,
        name: this.name,
        order: { price: "asc" },
        page: this.page,
        page_size: 10,
        plat_category_id: category_id,
        shop_category_id: [],
        spec_value_id: [],
      }).then((res) => {
        if (res.code === 200) {
          this.page++;
          this.goodsList.push(...res.data.data);
          this.loading = false;
          if (this.goodsList.length == res.data.total) {
            this.finished = true;
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.top-box-out {
  width: 100%;
  height: 165px;
  position: relative;
  background: #fafafa;

  .top-box-in {
    width: 100%;
    height: 165px;
    position: fixed;
    // height: 32px;
    background: #fafafa;
    z-index: 9999;

    .ipt-box-out {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 20px 18px;
      box-sizing: border-box;
      & > i {
        font-size: 20px;
        text-align: left;
      }
      & > img {
        width: 20px;
        height: 20px;
      }
      .ipt-box {
        width: 80%;
        height: 32px;
        border: 1px solid $primary;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
        & > input {
          width: 90%;
          height: 85%;
          border: none;
          background: transparent;
        }
      }
    }
  }
}
.cate-list {
  width: 100%;
  display: flex;
  align-items: center;

  & > div {
    width: 88px;
    height: 100px;
    line-height: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    .img-box {
      width: 48px;
      height: 48px;
      background: #fafafa;
      border-radius: 10px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > p {
      color: rgba(108, 108, 108, 1);
      font-size: 12px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
      padding-top: 10px;
    }
  }
}
.goods-list {
  width: 100%;
  padding: 0 11px;
  box-sizing: border-box;

  .active {
    border-color: #ca151d !important;
  }
  & > div {
    height: 165px;
    line-height: 20px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: 1px solid transparent;
    .left {
      & > img {
        width: 90px;
        height: 90px;
        background: #fafafa;
      }
      & > p {
        color: rgba(255, 58, 48, 1);
        font-size: 14px;
        font-weight: bold;
        padding-top: 10px;
      }
    }
    .right {
      width: 74%;
      padding-left: 9px;
      box-sizing: border-box;
      .title {
        color: rgba(16, 16, 16, 1);
        font-size: 15px;
        text-align: left;
        font-weight: bolder;
        padding-bottom: 11px;
      }
      .title-sub {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        line-height: 20px;
        height: 63px;
      }
      .guige {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        width: 100%;
      }
    }
  }
}
.ellipsisText3 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3; // 设置两行文字溢出
  -webkit-box-orient: vertical;
}
.pop-content {
  width: 100%;
  height: 300px;
  top: 300px !important;
}
.cs-list .van-overlay,
.cs-list .van-popup {
  top: 165px;
}
.pop-content {
  width: 100%;
  padding: 10px 10px 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  .active-item {
    color: $primary;
  }
  & > div {
    width: 23%;
    background-color: var(--backgroundBase, #f5f7fa);
    color: var(--textPrimary, #303133);
    margin-right: 2%;
    margin-bottom: 10px;
    text-align: center;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 5px;
    box-sizing: border-box;
  }
}
.no-data{
  padding: 10px 0
}
</style>